const axios = require('axios')

let axiosInitHeaders = {
    accept: 'application/json',
}

const Axios = axios.create({
    headers: axiosInitHeaders
});

Axios.defaults.withCredentials = false
Axios.defaults.baseURL = process.env.VUE_APP_API_URL;

Axios.interceptors.response.use(response => response, async function (error) {
    if (error) {
        const { response } = error;
        if (axios.isCancel(error)){
            return new Promise(() => {});
        }
        // eslint-disable-next-line no-prototype-builtins
        if (response && response.status === 403 && response.data.hasOwnProperty('detail') && response.data.detail.includes('CSRF')) {
            window.location.reload()
        }
        return Promise.reject(error);
    }
})

const Api = {
    upload(data) {
        return Axios.post('/api/image/', data);
    },
}


export default {
    Api: Api,
    BASE_URL: process.env.VUE_APP_API_URL,
}

