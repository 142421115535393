<template>
    <div class="">
        <div class="col-md-12">
            <div class="header">
                <div class="header__logo">
                    <img src="../assets/logo_om.svg" alt="logo" />
                </div>
            </div>
        </div>
        <div class="col-md-12 title">
            <h1>Maak je eigen OMdat-poster</h1>
        </div>
    </div>
</template>

<script>
export default {
  name: 'HeaderComponent',
};
</script>

<style scoped>
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    text-align: center;
}

.header__logo {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
}

.header__logo img {
    max-width: 100%;
    height: auto;
    padding-bottom: 10px;
}

.title h1{
    text-align: center;
    background: #001935;
    color: white;
    line-height: 70px;
    margin: 0 64px 0 0px;
    width:100%;
    font-size: 25px;
    padding:0px;
}

@media (max-width: 768px) {
    .title h1 {
        font-size: 24px;
        line-height: 36px;
        padding: 16px;
    }
    .title h1{
        font-size: 23px;
    }
}


</style>