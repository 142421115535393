<template>
    <div class="row">
        <div class="col-md-12 col-sm-12">
            <div class="sub-title">
                <h2>Maak je eigen <b>OMdat</b> poster, word ambassadeur van de campagne.</h2>
            </div>
        </div>
        <div class="col-md-6 col-sm-12">
            <div id="photo-frame">
                <img v-if="!selectedImage && selectedSize === 'A4'" src="../../assets/example_1.png" alt="placeholder" class="img-fluid">
                <img v-else-if="!selectedImage && selectedSize !== 'A4'" src="../../assets/example_2.png" alt="placeholder" class="img-fluid">
                <img v-else :src="selectedImage" alt="selected image" class="img-fluid">
                <div v-show="selectedImage" :class="selectedSize === 'A4' ? 'size-a4' : 'size-1200x628'" id="layover">
                    <img v-show="selectedSize === '1200x628'" src="../../assets/layer_1200.png" alt="layer" class="layer">
                    <img v-show="selectedSize === 'A4'" src="../../assets/layer_1240.png" alt="layer" class="layer">
                    <div id="name-and-function-title" class="dinpro">
                        {{ nameAndFunctionTitle }}
                    </div>
                    <div id="slogan" class="dinpro">
                        {{ slogan }}
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6 col-sm-12">
            <div>
                <h3>Hoe maak je een poster?</h3>
                <p>
                    Upload een goede foto van jezelf hiernaast. (max 10MB) De tekst kun je op het voorbeeld zien
                    terwijl je deze in de generator invoert.
                </p>
                <h3>Tips en tricks om een goede poster te maken.</h3>
                <p>
                    Dat begint natuurlijk al met een goede foto. Sta trots op de foto, je bent immers ambassadeur van de
                    campagne. Ga het liefst voor een gekleurde achtergrond staan of maak een mooie selfie op je
                    werkplek. Geen wit of zwart. En vermijd lichte kleding, anders is de tekst <b>OMdat</b> met je eigen slogan
                    niet of minder goed leesbaar. Let er wel op dat als je de foto op je werkplek maakt je geen herleidbare
                    zaken of informatie deelt die niet voor de buitenwereld geschikt is.
                </p>
                <p>
                    Staat je foto goed? Sta je er trots en goed op? Druk op de knop en kijk, daar is jouw echte, eigen
                    <b>OMdat</b> poster. Die poster kun je printen op maximaal A4-formaat. Ook kun je kiezen een social post
                    te maken voor je LinkedIn account. Vertel de buitenwereld vooral waarom jij bij het OM werkt. Vergeet
                    bij het plaatsen niet om <b><a target="_blank" href="https://twitter.com/search?q=%23OMdat&src=typed_query">#OMdat</a></b> toe te voegen!
                </p>
            </div>
            <div id="omdat-form">
                <div class="form-group">
                    <label>Selecteer het gewenste output-formaat</label>
                    <div class="custom-control custom-radio mt-2">
                        <input type="radio" id="sizeA4" name="pictureSize" class="custom-control-input" value="A4" v-model="selectedSize">
                        <label class="custom-control-label" for="sizeA4"><b>A4 (150DPI)</b> - 1240 x 1754 pixels</label>
                    </div>
                    <div class="custom-control custom-radio mt-2">
                        <input type="radio" id="size1200x628" name="pictureSize" class="custom-control-input" value="1200x628" v-model="selectedSize">
                        <label class="custom-control-label" for="size1200x628"><b>LinkedIn</b> - 1200 x 628 pixels</label>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-md-6 col-sm-12">
                        <div class="form-group">
                            <label for="first_name">
                                Voornaam
                                <span class="error" v-show="hasError('first_name')">
                                    - vul dit veld in
                                </span>
                            </label>
                            <input maxlength="15" type="text" class="form-control" id="first_name" v-model="first_name">
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <div class="form-group">
                            <label for="last_name">
                                Achternaam
                                <span class="error" v-show="hasError('last_name')">
                                    - vul dit veld in
                                </span>
                            </label>
                            <input maxlength="20"  type="text" class="form-control" id="last_name" v-model="last_name">
                        </div>
                    </div>
                </div>
                <div class="form-group mt-2">
                    <label for="function_title">
                        Functietitel
                        <span class="error" v-show="hasError('function_title')">
                            - vul dit veld in
                        </span>
                    </label>
                    <input maxlength="16" v-model="function_title" type="text" class="form-control" id="function_title">
                </div>
                <div class="form-group mt-2">
                    <label for="because">
                        Ik werk bij het OM, OMDAT…
                        <span class="error" v-show="hasError('slogan')">
                            - vul dit veld in
                        </span>
                    </label>
                    <input type="text" v-model="slogan" class="form-control" id="because" maxlength="75" placeholder="Ik een bijdrage wil leveren aan een veilige samenleving">
                </div>
                <div v-show="!selectedImage" class="form-group mt-4">
                    <div class="row">
                        <div class="col-md-6 col-sm-12">
                            <input type="file" @change="previewImage" class="form-control-file" id="hiddenFileInput" style="display: none;">
                            <button type="button" class="btn btn-primary mt-3" onclick="document.getElementById('hiddenFileInput').click();">
                                Selecteer foto (max 10MB)
                            </button>
                        </div>
                        <div class="col-md-6 col-sm-12 mt-3">
                            <p>
                                Tip: Voor het beste resultaat kun je het beste tegen een effen achtergrond aan staan en geen wit dragen
                            </p>
                        </div>
                    </div>
                </div>
                <div v-show="selectedImage" class="form-group mt-4">
                    <div class="row">
                        <div class="col-md-6 col-sm-12">
                            <a style="max-width: 100%; overflow:hidden" @click="selectedImage = null" id="selected-image-name" class="btn pb-1">
                                <span style="max-width: 100%; overflow:hidden">{{ selectedImage ? fileName : '' }}<i class="fa fa-close"></i></span>
                            </a>
                        </div>
                        <div class="col-md-6 col-sm-12">
                            <button @click="downloadImage" type="button" class="btn btn-primary">
                                Download poster
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import agent from '../../agent.js';
import Swal from 'sweetalert2';


export default {
    name: 'HomePage',
    data() {
        return {
            selectedSize: 'A4',
            selectedImage: null,
            fileName: null,
            first_name: '',
            last_name: '',
            slogan: '',
            function_title: '',
            requiredFields: [],
            errorFields: []
        }
    },
    watch: {
        selectedSize() {
            const fileInput = document.getElementById('hiddenFileInput');
            if (fileInput && fileInput.files.length > 0) {
                this.previewImage({target: {files: [fileInput.files[0]]}});
            }
            this.resizeNameAndFunctionTitle();
        },
        selectedImage() {
            if (this.selectedImage) {
                const fileInput = document.getElementById('hiddenFileInput');
                if (fileInput) {
                    this.fileName = fileInput.files[0].name;
                }else {
                    this.fileName = null;
                }
            }else {
                document.getElementById('hiddenFileInput').value = '';
                this.fileName = null;
            }
        }
    },
    methods: {
        hasError(field) {
            return this.errorFields.includes(field);
        },
        base64ToBlob(base64, mimeType) {
            const byteCharacters = atob(base64);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            return new Blob([byteArray], {type: mimeType});
        },
        downloadImage() {
            const base64String = this.selectedImage.split(',')[1];
            const mimeType = this.selectedImage.split(';')[0].split(':')[1];

            if(this.validateForm()) {
                const imageBlob = this.base64ToBlob(base64String, mimeType);
                const formData = new FormData();
                formData.append('image', imageBlob, 'upload.png');

                // Append other form data
                formData.append('first_name', this.first_name);
                formData.append('last_name', this.last_name);
                formData.append('function_title', this.function_title);
                formData.append('slogan', this.slogan);
                formData.append('size', this.selectedSize);

                agent.Api.upload(formData).then(response => {
                   window.location.href = `${agent.BASE_URL}/api/image/${response.data.identifier}`;
                }).catch(error => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Er is iets misgegaan. Probeer het later opnieuw.',
                        confirmButtonColor: '#001935',
                    });
                    console.log(error);
                });
            }
        },
        validateForm() {
            let self = this;
            this.errorFields = [];
            let isValid = true;
            this.requiredFields.forEach(field => {
                self[field] = self[field].trim();
                if (!self[field]) {
                    self.errorFields.push(field);
                    isValid = false;
                }
            });
            return isValid;
        },
        previewImage(event) {
            const file = event.target.files[0];
            if (file && file.type.match('image.*')) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    const image = new Image();
                    image.src = e.target.result;
                    image.onload = () => {
                        if (file.size > 10000000) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: `De afbeelding is te groot (${(file.size/1000000).toFixed(1)}MB). Probeer een afbeelding kleiner dan 10MB.`,
                                confirmButtonColor: '#001935',
                            });
                            document.getElementById('hiddenFileInput').value = '';
                            return;
                        }
                        if (this.selectedSize === '1200x628') {
                            this.cropImage(image, 1200, 628);
                        } else if (this.selectedSize === 'A4') {
                            this.cropImage(image, 1240, 1754);
                        }
                    };
                };
                reader.readAsDataURL(file);
            } else {
                this.selectedImage = null;
            }
        },
        cropImage(image, targetWidth, targetHeight) {
            // Initial calculations
            const sourceWidth = image.width;
            const sourceHeight = image.height;
            let scale = targetHeight / sourceHeight;  // Start by matching the target height
            let scaledWidth = sourceWidth * scale;
            let scaledHeight = targetHeight;

            // Adjust width if the scaled width is less than the target width
            if (scaledWidth < targetWidth) {
                scale = targetWidth / sourceWidth;
                scaledWidth = targetWidth;
                scaledHeight = sourceHeight * scale;
            }

            const canvas = document.createElement('canvas');
            canvas.width = targetWidth;
            canvas.height = targetHeight;
            const ctx = canvas.getContext('2d');

            // Calculate horizontal offset to center the image horizontally
            const offsetX = (targetWidth - scaledWidth) / 2;

            // Determine vertical offset
            let offsetY = 0;  // Set offsetY to 0 to ensure cropping happens from the bottom if needed
            if (scaledHeight < targetHeight) {
                offsetY = (targetHeight - scaledHeight) / 2;  // Center vertically only if the image is shorter than the target height
            }

            // Clear the canvas and draw the image
            ctx.clearRect(0, 0, targetWidth, targetHeight);
            ctx.drawImage(image, offsetX, offsetY, scaledWidth, scaledHeight);

            this.selectedImage = canvas.toDataURL(); // Set the preview image URL
        },
        resizeNameAndFunctionTitle() {
            let container = document.querySelector('#photo-frame');
            let width = container.offsetWidth; // Get the container width in pixels
            if(this.selectedSize === '1200x628') {
                document.querySelector('#name-and-function-title').style.left = width * 0.09 + 'px';
                document.querySelector('#slogan').style.left = width * 0.09 + 'px';
            } else {
                document.querySelector('#name-and-function-title').style.left = width * 0.12 + 'px';
                document.querySelector('#slogan').style.left = width * 0.12 + 'px';
            }
            document.querySelector('#name-and-function-title').style.fontSize = width * 0.03 + 'px';
            document.querySelector('#slogan').style.fontSize = width * 0.04 + 'px';
        }

    },
    computed: {
        nameAndFunctionTitle() {
            let text = '';
            if (this.first_name && !this.last_name) {
                text += this.first_name;
            }
            if (!this.first_name && this.last_name) {
                text += this.last_name;
            }
            if (this.first_name && this.last_name) {
                text += this.first_name + ' ' + this.last_name;
            }
            if (this.function_title) {
                if(text) {
                    text += ' - ' + this.function_title;
                } else {
                    text += this.function_title;
                }
            }
            return text
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.resizeNameAndFunctionTitle();
        });

        window.addEventListener('resize', this.resizeNameAndFunctionTitle);

    }
};

</script>

<style scoped>
    @font-face {
        font-family: "Meta Sans";
        src: url('../../fonts/meta.woff2') format('woff2');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: "DINPro";
        src: url('../../fonts/DINPro Medium.otf') format('otf');
        font-weight: normal;
        font-style: normal;
    }

    .sub-title h2 {
        text-align: center;
        line-height: 24px;
        margin: 0 0px 0 0px;
        width:100%;
        font-size: 20px;
        padding:20px;
        color: var(--bs-heading-color);;
    }

    #photo-frame {
        background: #f4f4f4;
        padding: 20px;
        margin: 0;
        margin-bottom:15px;
        position: relative;
    }

    #photo-frame .logo {
        width: 50%;
        height: auto;
        margin-top: 22px;
    }

    .custom-radio input[type="radio"] {
        margin-right: 10px;
    }

    #omdat-form {
        background: #f4f4f4;
        padding: 20px;
        margin: 0;
    }

    #omdat-form #because {
        font-weight: bold;
    }

    img {
        width: 100%;
        height: auto;
    }

    #layover {
        position: absolute;
        z-index: 100;
        padding:20px;
        width: 100%;
        top:0px;
        left:0px;
    }

    button{
        background: #001935;
        border: 1px solid #001935;
        width: 100%;
        font-size: 22px;
    }

    button:hover{
        background: #022f61;
        border: 1px solid #022f61;
    }

    #selected-image-name {
        font-size: 22px;
        font-weight: bold;
        display: inline-block;
        border: 1px solid #001935;
        padding: 5px;
    }

    #selected-image-name span {
        color: #001935;
        cursor: pointer;
        display: block;
    }

    #selected-image-name span i {
        color: #001935;
        cursor: pointer;
        display: inline-block;
        float: right;
        margin-top:6px;
        margin-left:10px;
    }

    .error {
        color: red;
    }

    .dinpro {
        font-family: "DINPro", sans-serif;
        color:white;
    }

    .size-a4 #name-and-function-title {
        position: absolute;
        top:64%;
    }

    .size-a4 #slogan {
        position: absolute;
        top: 79%;
        font-weight: bold;
        line-height: 1.2;
        padding-right: 20px;
    }

    .size-1200x628 #name-and-function-title {
        position: absolute;
        top:46%;
    }

    .size-1200x628 #slogan {
        position: absolute;
        top: 74%;
        font-weight: bold;
        line-height: 1.2;
        padding-right: 20px;
    }


</style>

