<template>
<div id="footer">
    <div class="row">
        <div class="col-md-3 offset-md-3 col-sm-12">
            <p>
                <a href="#privacy-policy">Privacy Policy</a>
            </p>
            <p>
                <a href="#terms-of-service">Terms of Service</a>
            </p>
        </div>
        <div class="col-md-3 col-sm-12">
<!--            <p>-->
<!--                <a href="https://sedna.sofware" target="_blank">SEDNA.software</a>-->
<!--            </p>-->
            <p>
                <a href="#disclaimer">Disclaimer</a>
            </p>

        </div>
    </div>
</div>
</template>
<script>
export default {
  name: 'FooterComponent',
};
</script>

<style>
#footer {
    background-color: #91d2f3;
    margin-top: 2rem;
    padding: 2.5rem;
    font-size: 1.5rem;
}

#footer a {
    color: #000;
    text-decoration: none;
}
</style>