<template>
    <router-view/>
</template>

<script>
export default {
    name: 'App',
};
</script>


<style>
@font-face {
    font-family: "Meta Sans";
    src: url('./fonts/meta.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
</style>
