<template>
    <div id="page-content">
        <div class="container-fluid">
            <Header/>

        </div>
        <div class="container">
            <generator-page/>
        </div>
        <Footer/>
    </div>

</template>

<script>

import GeneratorPage from './pages/GeneratorPage.vue';
import Header from './Header.vue';
import Footer from './Footer.vue';



export default {
    name: 'index_page',
    components: {
        GeneratorPage, Header, Footer
    },
};
</script>

<style scoped>
    #page-content {
        font-family: "Meta Sans", Verdana, Arial, sans-serif;
        color:#001935;
    }

    .container-fluid {
        background: #f4f4f4;
        padding:0px;
    }


</style>
